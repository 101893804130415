import { Spinner } from '@capturi/ui-components'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useKeyTopicsDiscoveryResult } from './useKeyTopicsDiscovery'

type Props = { discoveryUid: string; organizationUid: string | undefined }

const KeyTopicsDiscoveryLoading: React.FC<Props> = ({
  discoveryUid,
  organizationUid,
}) => {
  const { data } = useKeyTopicsDiscoveryResult(discoveryUid)
  const queryClient = useQueryClient()

  if (data?.status === 'Success') {
    queryClient.invalidateQueries({
      queryKey: ['superpowers', 'keyTopicsDiscoveries', organizationUid],
    })
  }
  return <Spinner />
}

export default KeyTopicsDiscoveryLoading

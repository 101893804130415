import { useSet } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import { Box, Flex, Grid } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { CurrentlyRunningColumn } from './Column'

const integrations = [
  {
    name: 'Adversus v2',
    type: 'adversus',
    color: '#3639a4',
  },
  {
    name: 'Demo v3',
    type: 'demoV3',
    color: '#216D97',
  },
  {
    name: 'Genesys Chat',
    type: 'genesys-chat',
    color: '#FF4F1F',
  },
  {
    color: '#FF4F1F',
    type: 'genesys',
    name: 'Genesys',
  },
  {
    color: '#59195D',
    type: 'boostai',
    name: 'BoostAi',
  },
  {
    color: '#f6921d',
    type: 'liveagent',
    name: 'LiveAgent',
  },
  {
    color: '#B41730',
    type: 'dsb_kcit',
    name: 'DSB',
  },
  {
    color: '#2980c9',
    type: 'oresundsbron-mail',
    name: 'oresundsbron-mail',
  },
  {
    color: '#005cff',
    type: 'cognigy-chat',
    name: 'Cognigy Chat',
  },
  { name: 'liveAgent Audio', color: '#f6921e', type: 'live-agent-audio' },
  {
    name: 'Genesys Chat As Voice',
    color: '#4e59aa',
    type: 'genesys-chat-as-voice',
  },
] as const
type IntegratType = (typeof integrations)[number]['type']
type Integration = (typeof integrations)[number]

const integrationsMap = new Map(integrations.map((i) => [i.type, i]))

export const CurrentlyRunning: React.FC = () => {
  const s = useSet<IntegratType>([])

  const sorted = useMemo(
    () => integrations.toSorted((a, b) => a.name.localeCompare(b.name)),
    [],
  )

  const toShow = useMemo(() => {
    if (s.size === 0) return sorted

    //To add the new selected to the start of the "list"
    //We do some funky stuff
    const result: Integration[] = []
    s.forEach((i) => {
      const r = integrationsMap.get(i)
      if (r) result.push(r)
    })

    return result.reverse()
  }, [s, sorted])

  return (
    <Box>
      <Flex>
        {sorted.map((i) => (
          <Tag
            {...i}
            key={i.type}
            isSelected={s.has(i.type)}
            onClick={() => {
              s.has(i.type) ? s.delete(i.type) : s.add(i.type)
            }}
          />
        ))}
      </Flex>
      <Button onClick={s.clear} ml="2" variant="link">
        Clear
      </Button>
      <Box overflowX={'scroll'}>
        <Grid
          templateColumns={{ sm: '1fr', lg: 'repeat(auto-fill, 300px)' }}
          overflowX={'scroll'}
          width={{ sm: '100%', lg: toShow.length * 330 - 30 }}
          gap="30px"
          mt="8"
        >
          {toShow.map((i) => (
            <CurrentlyRunningColumn key={i.type} {...i} />
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

const Tag: React.FC<{
  color: string
  type: string
  name: string
  isSelected: boolean
  onClick: (type: string) => void
}> = ({ color, name, type, isSelected, onClick }) => {
  return (
    <Box
      onClick={() => onClick(type)}
      cursor="pointer"
      py="1"
      px="2"
      m="2"
      as="span"
      color={isSelected ? 'white' : color}
      bg={!isSelected ? 'white' : color}
      border={`${color} solid 1px;`}
      borderRadius={'10px'}
    >
      {name}
    </Box>
  )
}

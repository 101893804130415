import { type ResponseError, downloadBlob } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Box, Flex, IconButton, Input, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { MdArrowBackIosNew } from 'react-icons/md'

type Props = {
  integrationKey: string
  integrationType: string
  showPathChooser?: boolean
} & BaseModalComponentProps
type File = {
  date: Date
  file: string
  size: number
}

const mapData = (files: File[]): File[] => files.toReversed()

export const FilesOnSftpModal: React.FC<Props> = ({
  onClose,
  integrationKey,
  showPathChooser,
  integrationType,
}) => {
  const toast = useToast()
  const [path, setPath] = useState('./')
  const { data, isLoading, error } = useQuery<File[], ResponseError, File[]>({
    queryKey: [
      'superpowers',
      'integrations',
      integrationType,
      integrationKey,
      'ftp',
      path,
      showPathChooser,
    ],
    queryFn: async () =>
      request.get(
        `superpowers/integration/sftp/${integrationKey}/${integrationType}/files`,
        {
          searchParams: {
            path,
          },
        },
      ),
    select: mapData,
  })

  const handleDownload = async (filename: string) => {
    toast({ title: 'Downloading file', description: filename, status: 'info' })

    await downloadBlob(
      `superpowers/integration/sftp/${integrationKey}/${integrationType}/download?path=${path}${filename}`,
      filename,
    )
  }

  return (
    <Modal isOpen onClose={onClose} size="6xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Files on sftp</ModalHeader>

          <ModalCloseButton />
          <Flex alignItems="center">
            <IconButton
              variant="ghost"
              colorScheme="primary"
              icon={<MdArrowBackIosNew />}
              aria-label={'go back'}
              onClick={() => setPath((p) => p.replace(/[^\/]*\S\/$/gm, ''))}
            />
            <Input
              value={path}
              onChange={(e) => setPath(e.currentTarget.value)}
            />
          </Flex>

          <ModalBody>
            {error && <Text>{error.message}</Text>}
            {isLoading && (
              <Flex justifyContent="center" mt="50px">
                <Spinner delay={0} />
              </Flex>
            )}

            {data?.map((d) => (
              <Flex
                justifyContent="space-between"
                key={d.file}
                fontFamily="monospace"
                cursor="default"
                _hover={{ color: 'primary.400' }}
              >
                <Flex>
                  <Box
                    mr="6"
                    onDoubleClick={() => setPath((p) => `${p}${d.file}/`)}
                  >
                    {d.date.toISOString()}
                  </Box>

                  <div
                    onDoubleClick={() =>
                      d.size === 0
                        ? setPath((p) => `${p}${d.file}/`)
                        : handleDownload(d.file)
                    }
                  >
                    {d.file}
                  </div>
                </Flex>
                <div>{d.size}</div>
              </Flex>
            ))}
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button primary onClick={onClose}>
              <Trans>Close</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

import { ReportFormModel } from '@capturi/api-systemadmin'
import { useInput } from '@capturi/react-utils'
import { Button, useToast } from '@capturi/ui-components'
import {
  Box,
  FormLabel,
  Grid,
  Input,
  Select,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { type FC } from 'react'
import { useParams } from 'react-router-dom'
import { Title } from '../../../../utils'
import { ReportCard } from './ReportCard'
import { useCreateCustomReport, useCustomReports } from './useCustomReports'

export const CustomReports: FC = () => {
  const { uid: organizationUid } = useParams()
  const { data: metabaseReports } = useCustomReports(organizationUid)
  const { mutate: createReport, isPending: isCreateLoading } =
    useCreateCustomReport(organizationUid)

  const [state, setState] = useInput({
    title: '',
    description: '',
    metabaseId: '',
    host: '',
  })

  const toast = useToast()
  const handleCreate = (formData: ReportFormModel): void => {
    createReport(
      {
        organizationUid,
        report: {
          ...formData,
          metabaseId: Number.parseInt(formData.metabaseId, 10),
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${formData.title} report created successfully`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: 'Could not create Metabase report',
            status: 'error',
            description: error.message,
          })
        },
      },
    )
  }

  const hasError =
    (state.host === 'Sync' && Number.parseInt(state.metabaseId, 10) <= 10000) ||
    (state.host === 'Snapshot' &&
      Number.parseInt(state.metabaseId, 10) >= 10000)
  const inputBorderColor = hasError ? 'danger' : 'initial'

  return (
    <VStack
      mt={8}
      mb="20"
      alignItems="flex-start"
      spacing={6}
      border={'solid 2px #df3f3f'}
      p="4"
      borderRadius="20px"
    >
      <div>
        <Text color="#df3f3f" fontSize="x-large">
          Analytics only
        </Text>
        <Text m="0" fontSize="xl" color="#df3f3f">
          Do not touch unless you can explain the key theoretical idea behind
          the modern Hopfield network
        </Text>
      </div>
      <SimpleGrid
        columns={1}
        spacing={3}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="6px"
        p={4}
      >
        <Box alignItems="flex-start">
          <Text>
            <FormLabel>Title</FormLabel>
          </Text>
          <Input
            {...setState.title}
            isDisabled={isCreateLoading}
            placeholder="Title"
          />
        </Box>
        <Box alignItems="flex-start">
          <Text>
            <FormLabel>Description</FormLabel>
          </Text>
          <Input
            {...setState.description}
            isDisabled={isCreateLoading}
            placeholder="Description"
          />
        </Box>
        <Box alignItems="flex-start">
          <Text>
            <FormLabel>Id</FormLabel>
          </Text>
          <Input
            borderColor={inputBorderColor}
            _focus={{
              borderColor: inputBorderColor,
            }}
            _focusVisible={{
              borderColor: inputBorderColor,
            }}
            type="number"
            {...setState.metabaseId}
            isDisabled={isCreateLoading}
            placeholder="Id"
          />
        </Box>
        <Box>
          <FormLabel htmlFor="host">
            <Text>Host</Text>
          </FormLabel>
          <Select isDisabled={isCreateLoading} {...setState.host}>
            <option disabled value={''}>
              Select an option
            </option>
            <option value={'Snapshot'}>Snapshot</option>
            <option value={'Sync'}>Sync</option>
          </Select>
        </Box>
        <Box>
          <Tooltip
            label={
              state.host === 'Sync' && hasError ? (
                <Text>
                  If host is{' '}
                  <Text as="span" textDecor="underline">
                    Sync
                  </Text>{' '}
                  the{' '}
                  <Text as="span" textDecor="underline">
                    Report id
                  </Text>{' '}
                  must be higher than 10000
                </Text>
              ) : (
                <Text>
                  If host is{' '}
                  <Text as="span" textDecor="underline">
                    Snapshot
                  </Text>{' '}
                  the{' '}
                  <Text as="span" textDecor="underline">
                    Report id
                  </Text>{' '}
                  must be less than 10000
                </Text>
              )
            }
            hasArrow
            placement="top"
            isDisabled={!hasError}
          >
            <Button
              isDisabled={
                isCreateLoading ||
                hasError ||
                !(state.title && state.metabaseId && state.host)
              }
              isLoading={isCreateLoading}
              primary
              onClick={() => handleCreate(state)}
            >
              Create report
            </Button>
          </Tooltip>
        </Box>
      </SimpleGrid>
      <Title>Metabase reports</Title>
      <Grid templateColumns="repeat(3, 1fr)" gap={2} w="100%">
        {metabaseReports
          ?.filter((r) => r.standardReportUid == null)
          .map((report) => (
            <ReportCard
              key={report.uid}
              title={report.title}
              description={report.description}
              metabaseId={report.metabaseId.toString()}
              host={report.host}
              reportUid={report.uid}
              organizationUid={organizationUid}
            />
          ))}
      </Grid>
    </VStack>
  )
}

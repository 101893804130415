import { type FC } from 'react'
import { CustomReports } from './CustomReports/CustomReports'
import StandardReports from './StandardReports/StandardReports'

export const MetabaseReports: FC = () => {
  return (
    <div>
      <StandardReports />
      <CustomReports />
    </div>
  )
}

import { Report, ReportRequestModel } from '@capturi/api-systemadmin'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { apiVersion, baseUrl } from '../constants'

export type UpdateReportPayload = {
  reportUid: string
  organizationUid: string | undefined
  report: Partial<ReportRequestModel>
}

export type DeleteReportPayload = {
  reportUid: string
  organizationUid: string | undefined
}

export type CreateReportPayload = {
  organizationUid: string | undefined
  report: ReportRequestModel
}

export type ReportResponse = {
  reports: Report[]
}

export const useCustomReports = (
  organizationUid: string | undefined,
): UseQueryResult<Report[], ResponseError> =>
  useQuery({
    queryKey: ['customReport', 'organisation', organizationUid],
    queryFn: () =>
      request.get<Report[]>(`${baseUrl}/${organizationUid}/list/${apiVersion}`),
    enabled: !!organizationUid,
  })

export const useUpdateCustomReport = (
  organizationUid: string | undefined,
): UseMutationResult<Report, ResponseError, UpdateReportPayload> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['customReport', 'organisation', organizationUid],
    mutationFn: ({
      reportUid,
      organizationUid,
      report,
    }: UpdateReportPayload) => {
      return request.patch<Report>(
        `${baseUrl}/${organizationUid}/${reportUid}?api-version=1`,
        {
          json: report,
        },
      )
    },
    onSuccess: (newReport) => {
      queryClient.setQueryData<Report[]>(
        ['customReport', 'organisation', organizationUid],
        (oldData) => {
          if (!oldData) {
            return [newReport]
          }
          return oldData.map((f) => (f.uid === newReport.uid ? newReport : f))
        },
      )
      queryClient.setQueryData<Report>(
        ['customReport', 'report', newReport.uid],
        () => {
          return newReport
        },
      )
    },
  })
}

export const useDeleteCustomReport = (
  organizationUid: string | undefined,
): UseMutationResult<Report, ResponseError, DeleteReportPayload> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['customReport', 'organisation', organizationUid],
    mutationFn: ({ reportUid, organizationUid }: DeleteReportPayload) =>
      request.delete<Report>(`${baseUrl}/${organizationUid}/${reportUid}`),
    onSuccess: (_, payload) => {
      queryClient.setQueryData<Report[]>(
        ['customReport', 'organisation', organizationUid],
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.filter((f) => f.uid !== payload.reportUid)
        },
      )
    },
  })
}

export const useCreateCustomReport = (
  organizationUid: string | undefined,
): UseMutationResult<Report, ResponseError, CreateReportPayload> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['customReport', 'organisation', organizationUid],
    mutationFn: ({ organizationUid, report }: CreateReportPayload) =>
      request.post<Report>(`${baseUrl}/${organizationUid}`, {
        json: report,
      }),
    onSuccess: (newReport) => {
      queryClient.setQueryData<Report[]>(
        ['customReport', 'organisation', organizationUid],
        (oldData) => {
          if (!oldData) {
            return [newReport]
          }
          return [newReport, ...oldData]
        },
      )
    },
  })
}

import analytics from '@capturi/analytics'
import { ReportFormModel } from '@capturi/api-systemadmin'
import { useInput } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import {
  Box,
  Flex,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react'
import { type FC, useEffect } from 'react'
import {
  useDeleteCustomReport,
  useUpdateCustomReport,
} from './useCustomReports'

type Props = {
  organizationUid: string | undefined
  title: string
  metabaseId: string
  description: string
  reportUid: string
  host: string
}

export const ReportCard: FC<Props> = ({
  organizationUid,
  title,
  metabaseId,
  description,
  reportUid,
  host,
}) => {
  const [state, setState] = useInput({
    title: title,
    description: description,
    metabaseId: metabaseId,
    host: host,
  })
  const toast = useToast()

  const { mutate: updateReport, isPending: isUpdateLoading } =
    useUpdateCustomReport(organizationUid)
  const { mutate: deleteReport, isPending: isDeleteLoading } =
    useDeleteCustomReport(organizationUid)

  const inProgress = isUpdateLoading || isDeleteLoading

  const handleUpdate = (
    formData: ReportFormModel,
    reportUid?: string,
  ): void => {
    if (reportUid) {
      updateReport(
        {
          reportUid: reportUid,
          organizationUid: organizationUid,
          report: {
            ...formData,
            metabaseId: Number.parseInt(formData.metabaseId, 10),
          },
        },
        {
          onSuccess: () => {
            toast({
              title: `${formData.title} updated successfully`,
              status: 'success',
            })
          },
          onError: (error) => {
            toast({
              title: 'Could not update Metabase report',
              status: 'error',
              description: error.message,
            })
          },
        },
      )
    }
  }
  const handleDelete = (
    reportUid: string,
    organizationUid: string | undefined,
  ): void => {
    deleteReport(
      {
        reportUid: reportUid,
        organizationUid: organizationUid,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Report deleted successfully',
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: 'Could not delete Metabase report',
            status: 'error',
            description: error.message,
          })
        },
      },
    )
  }

  useEffect(() => {
    analytics.event('reports_current_for_org', {
      metabaseId,
      host,
      reportUid,
      title,
    })
  }, [reportUid, metabaseId, host, title])

  return (
    <SimpleGrid
      columns={1}
      spacing={3}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="6px"
      p={4}
    >
      <Box alignItems="flex-start">
        <Text>
          <FormLabel>Title</FormLabel>
        </Text>
        <Input
          {...setState.title}
          isDisabled={inProgress}
          placeholder="Title"
        />
      </Box>
      <Box alignItems="flex-start">
        <Text>
          <FormLabel>Description</FormLabel>
        </Text>
        <Input
          {...setState.description}
          isDisabled={inProgress}
          placeholder="Description"
        />
      </Box>
      <Box alignItems="flex-start">
        <Text>
          <FormLabel>Id</FormLabel>
        </Text>
        <Input
          {...setState.metabaseId}
          isDisabled={inProgress}
          placeholder="Id"
        />
      </Box>
      <Box alignItems="flex-start">
        <FormLabel htmlFor="host">
          <Text>Host</Text>
        </FormLabel>
        <Select isDisabled={inProgress} id="role" key="role" {...setState.host}>
          <option value={'Snapshot'}>Snapshot</option>
          <option value={'Sync'}>Sync</option>
        </Select>
      </Box>
      <Flex justifyContent="flex-end">
        <Button
          mt={3}
          size="sm"
          colorScheme="red"
          isLoading={isDeleteLoading}
          isDisabled={isUpdateLoading}
          onClick={() => handleDelete(reportUid, organizationUid)}
        >
          Delete
        </Button>
        <Button
          ml={2}
          mt={3}
          primary
          isLoading={isUpdateLoading}
          isDisabled={isDeleteLoading}
          onClick={() => handleUpdate(state, reportUid)}
        >
          Update
        </Button>
      </Flex>
    </SimpleGrid>
  )
}

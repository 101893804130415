import { FormControl, FormLabel, Input, Switch } from '@chakra-ui/react'
import React, { Dispatch, ReactElement, SetStateAction } from 'react'

import { FieldOption } from '../../../integrations'

type Field = Record<string, string | boolean | undefined>

type Props = {
  options: FieldOption[]
  setState: Dispatch<SetStateAction<Record<string, string | boolean>>>
  state: Field
  inProgress: boolean
}

const FieldConfig: React.FC<Props> = ({
  options,
  setState: _setState,
  state,
  inProgress,
}) => {
  const setState = (key: string, value: string | boolean): void => {
    _setState((s) => ({ ...s, [key]: value }))
  }

  const renderOption = (options: FieldOption): ReactElement => {
    const { key, label, defaultValue, type } = options

    if (type === 'boolean') {
      return (
        <FormControl display="flex" mt="4">
          <Switch
            id={key}
            isChecked={!!state[key] || !!defaultValue || false}
            onChange={(e) => setState(key, e.currentTarget.checked)}
            isDisabled={inProgress}
            mr="2"
          />
          <FormLabel htmlFor={key}>{label}</FormLabel>
        </FormControl>
      )
    }

    return (
      <FormControl mt="2" key={key}>
        <FormLabel mb="0" ml="1" htmlFor={key}>
          {label}
        </FormLabel>
        <Input
          isDisabled={inProgress}
          id={key}
          value={
            state[key]
              ? String(state[key])
              : defaultValue
                ? String(defaultValue)
                : ''
          }
          onChange={(event) => setState(key, event.currentTarget.value)}
          autoComplete="none"
        />
      </FormControl>
    )
  }

  return <div>{options.map(renderOption)}</div>
}

export default FieldConfig

import request from '@capturi/request/src/request'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

const generateUrl = (integrationType: string, integrationKey: string) =>
  `superpowers/integration/auth/${encodeURIComponent(
    integrationType,
  )}/${encodeURIComponent(integrationKey)}`

export const useIntegrationAuth = (
  integrationType: string,
  integrationKey: string,
) =>
  useQuery<Record<string, string | number>>({
    queryKey: ['integration', 'auth', integrationType, integrationKey],
    queryFn: () => request.get(generateUrl(integrationType, integrationKey)),
  })

export const useMutateIntegrationAuth = (
  integrationType: string,
  integrationKey: string,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: Record<string, string | number>) =>
      request.put(generateUrl(integrationType, integrationKey), {
        json: payload,
      }),
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries({
        queryKey: ['superpowers', 'integrations'],
      })
      queryClient.setQueryData<Record<string, string | number>>(
        ['integration', 'auth', integrationType, integrationKey],
        (oldData) => {
          if (!oldData) {
            return {}
          }
          return payload
        },
      )
    },
  })
}
